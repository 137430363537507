import { useEffect, useState, type PropsWithChildren } from 'react';
import { XClose } from '@kota/icons';
import { cn } from '@kota/utils';
import * as RadixDialog from '@radix-ui/react-dialog';

type Props = PropsWithChildren & {
  trigger?: JSX.Element;
  open?: boolean;
  size?: 'small' | 'medium' | 'large';
  onClose?: () => void;
  container?: HTMLElement;
};

export default function Dialog({ trigger, open, size, children, onClose, container }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => setIsOpen(open), [open]);

  return (
    <RadixDialog.Root
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);

        if (!open) onClose?.();
      }}
    >
      {trigger && <RadixDialog.Trigger>{trigger}</RadixDialog.Trigger>}

      <RadixDialog.Portal container={container}>
        <RadixDialog.Overlay className='bg-kota-neutral-90/80 fixed left-0 top-0 z-50 h-full w-full transition-all' />

        <RadixDialog.Content
          className={cn(
            'fixed left-1/2 top-1/2 z-50 w-[90vw] -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl transition-all',
            {
              'w-[538px]': size === 'small',
              'w-[768px]': size === 'medium',
              'w-[1024px]': size === 'large',
            },
          )}
        >
          {children}

          <RadixDialog.Close asChild>
            <button className='hover:bg-kota-neutral-5 absolute right-6 top-6 rounded-md p-1' aria-label='Close'>
              <XClose className='h-5 w-5' />
            </button>
          </RadixDialog.Close>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}
