import { ArrowLeft, ArrowRight } from '@kota/icons';
import { Spinner } from '@kota/ui';
import { Link, useNavigation, type LinkProps } from '@remix-run/react';

import { Button } from '~/src/ui';

type Navigation = Partial<LinkProps> & { label?: string };

type Props = {
  back?: Navigation & { onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void };
  next?: Navigation & { onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void };
};

export default function WizardFooter({ back, next }: Props): JSX.Element {
  const { state } = useNavigation();
  const isSubmitting = state === 'submitting';
  const isLoading = state === 'loading';
  const disabled = isSubmitting || isLoading;
  const defaultBackLabel = back?.label ?? 'Back';
  const defaultNextLabel = next?.label ?? 'Next';

  return (
    <div className='flex justify-between'>
      <div>
        {back?.to ? (
          <Link to={back.to} aria-disabled={disabled} onClick={back?.onClick}>
            <Button variant='outline' disabled={disabled}>
              <ArrowLeft className='h-5 w-5' />
              <div>{defaultBackLabel}</div>
            </Button>
          </Link>
        ) : back?.onClick ? (
          <Button type='button' variant='outline' disabled={disabled} onClick={back.onClick}>
            <ArrowLeft className='h-5 w-5' />
            <div>{defaultBackLabel}</div>
          </Button>
        ) : null}
      </div>

      {next?.to ? (
        <Link to={next.to} aria-disabled={disabled} onClick={next?.onClick}>
          <Button type='button' disabled={disabled}>
            <div>{defaultNextLabel}</div>
            {disabled ? <Spinner /> : <ArrowRight className='h-5 w-5' />}
          </Button>
        </Link>
      ) : (
        <Button type='submit' disabled={disabled} onClick={next?.onClick}>
          <div>{defaultNextLabel}</div>
          {isSubmitting ? <Spinner /> : <ArrowRight className='h-5 w-5' />}
        </Button>
      )}
    </div>
  );
}
