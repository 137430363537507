import * as React from 'react';
import { useState, type InputHTMLAttributes, type LegacyRef } from 'react';
import { Eye, EyeOff } from '@kota/icons';
import { FormError } from '@kota/ui';
import { cn } from '@kota/utils';
import { Label } from '@radix-ui/react-label';
import { useField } from 'remix-validated-form';

type ValidationBehavior = 'onSubmit' | 'onChange' | 'onBlur';

// @ts-expect-error - Something up with the `prefix` type
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  helper?: string | React.ReactNode;
  labelSize?: 'sm' | 'md' | 'lg';
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  form?: string;
  blurred?: boolean;
  required?: boolean;
  optional?: boolean;
  canTogglePassword?: boolean;
  width?: string;
  justify?: 'start' | 'end' | 'center';
  validationBehavior?: {
    initial?: ValidationBehavior;
    whenTouched?: ValidationBehavior;
    whenSubmitted?: ValidationBehavior;
  };
}

function BaseInput(
  {
    type,
    name,
    label,
    helper,
    labelSize = 'sm',
    prefix,
    suffix,
    form,
    blurred,
    required,
    optional,
    canTogglePassword,
    width,
    justify,
    validationBehavior = {
      initial: 'onSubmit',
      whenTouched: 'onSubmit',
      whenSubmitted: 'onChange',
    },
    ...rest
  }: InputProps,
  ref: LegacyRef<HTMLInputElement>,
) {
  const [displayPassword, setDisplayPassword] = useState(false);

  const { error, getInputProps } = useField(name, {
    formId: form,
    validationBehavior,
  });

  return (
    <div
      className={cn('flex w-full flex-col space-y-2', {
        hidden: type === 'hidden',
        'items-start': justify === 'start',
        'items-end': justify === 'end',
        'items-center': justify === 'center',
      })}
    >
      {label && (
        <div>
          {label && (
            <Label
              aria-labelledby={name}
              htmlFor={name}
              className={cn('group-focus-within:text-kota-primary-50 text-kota-text-90 block select-none font-medium', {
                'text-sm': labelSize === 'sm',
                'text-base': labelSize === 'md',
                'text-lg': labelSize === 'lg',
              })}
            >
              {label}
              {required ? ' *' : ''}
              {optional ? <span className='text-kota-text-20 font-normal'> (optional)</span> : ''}
            </Label>
          )}

          {helper && <div className='text-kota-text-30 text-sm'>{helper}</div>}
        </div>
      )}

      <div
        className={cn(
          'group relative flex h-12 w-full items-center space-x-1 rounded-xl border bg-white px-3 py-2 shadow-sm',
          'focus-within:ring-kota-primary-40 hover:border-kota-primary-40 focus-within:ring-2 focus-within:ring-offset-2 focus:outline-none',
          {
            'border-kota-neutral-20': !error,
            'border-red-500': error,
          },
        )}
        style={{ width }}
      >
        {prefix && <div className='flex items-center'>{prefix}</div>}

        <input
          {...rest}
          {...getInputProps({ id: name })}
          onChange={(e) => {
            rest.onChange?.(e);
            getInputProps()?.onChange?.(e);
          }}
          name={name}
          ref={ref}
          type={displayPassword && type === 'password' ? 'text' : type}
          className={cn(
            'text-kota-text-90 block h-full w-full border-0 p-0',
            'placeholder-kota-neutral-60 focus:ring-0 focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0',
            {
              'blur focus:blur-none': blurred,
            },
          )}
        />

        {suffix && <div className='flex items-center'>{suffix}</div>}

        {canTogglePassword && type === 'password' && (
          <button
            className='absolute right-4 top-1/2 mt-0.5'
            type='button'
            onClick={() => setDisplayPassword(!displayPassword)}
          >
            {displayPassword ? <EyeOff /> : <Eye />}
          </button>
        )}
      </div>

      {error && <FormError>{error}</FormError>}
    </div>
  );
}

export default React.forwardRef(BaseInput);
