'use client';

import * as React from 'react';
import { useEffect, useState } from 'react';
import { Check } from '@kota/icons';
import { FormError } from '@kota/ui';
import { cn } from '@kota/utils';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Label } from '@radix-ui/react-label';
import { useField } from 'remix-validated-form';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    label: string;
    form?: string;
  }
>(({ className, ...props }, ref) => {
  const [checked, setChecked] = useState<'indeterminate' | boolean>(false);
  const { error, getInputProps, validate } = useField(`${props.name}`, {
    formId: props.form,
    validationBehavior: {
      initial: 'onSubmit',
      whenTouched: 'onSubmit',
      whenSubmitted: 'onChange',
    },
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <div className='space-y-1.5'>
      <div className='flex items-center space-x-3'>
        <CheckboxPrimitive.Root
          ref={ref}
          checked={checked}
          onCheckedChange={setChecked}
          className={cn(
            'data-[state=checked]:bg-kota-primary-50 data-[state=checked]:border-kota-primary-50 focus-visible:ring-kota-primary-20 peer h-6 w-6 shrink-0 rounded-md border ring-offset-background data-[state=checked]:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            className,
            {
              'border-red-500': error,
              'border-kota-neutral-20': !error,
            },
          )}
          {...props}
        >
          <CheckboxPrimitive.Indicator className='flex items-center justify-center text-current' {...getInputProps()}>
            <Check className='h-4 w-4' />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>

        <Label htmlFor={props.name} className='text-kota-text-30 text-sm'>
          {props.label}
        </Label>
      </div>

      {error && <FormError>{error}</FormError>}
    </div>
  );
});

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export default Checkbox;
