import type { PropsWithChildren } from 'react';
import { cn } from '@kota/utils';

type Props = PropsWithChildren & {
  variant?: 'neutral' | 'success';
};

export default function Badge({ variant = 'neutral', children }: Props) {
  return (
    <div
      className={cn('inline-block rounded-md border px-2 py-1 text-sm', {
        'text-kota-neutral-90 bg-kota-success-10 border-kota-success-60': variant === 'success',
        'text-kota-neutral-90 bg-kota-neutral-5 border-kota-neutral-20': variant === 'neutral',
      })}
    >
      {children}
    </div>
  );
}
