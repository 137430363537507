import type { PropsWithChildren } from 'react';
import { cn } from '@kota/utils';

type Props = PropsWithChildren & {
  variant: 'warning' | 'primary' | 'secondary' | 'success' | 'primary-inverted';
};

export default function Pill({ variant, children }: Props): JSX.Element {
  const classes = cn('text-sm inline-block font-medium px-2 py-0.5 rounded-full', {
    'bg-kota-warning-10 text-kota-warning-90': variant === 'warning',
    'bg-kota-primary-10 text-kota-primary-60': variant === 'primary',
    'bg-kota-primary-60 text-kota-primary-10': variant === 'primary-inverted',
    'bg-kota-neutral-5 text-kota-text-40': variant === 'secondary',
    'bg-kota-success-10 text-kota-success-90': variant === 'success',
  });

  return <div className={classes}>{children}</div>;
}
