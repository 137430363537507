import { useEffect, useState } from 'react';
import * as RadixSwitch from '@radix-ui/react-switch';

type Props = RadixSwitch.SwitchProps;

export default function Switch({ checked, onCheckedChange }: Props): JSX.Element {
  const [state, setState] = useState<'checked' | 'unchecked'>(checked ? 'checked' : 'unchecked');

  useEffect(() => {
    if (checked !== undefined) setState(checked ? 'checked' : 'unchecked');
  }, [checked]);

  return (
    <RadixSwitch.Root
      checked={state === 'checked'}
      onCheckedChange={(checked) => {
        setState(checked ? 'checked' : 'unchecked');
        onCheckedChange?.(checked);
      }}
      className='bg-kota-neutral-20 data-[state=checked]:bg-kota-primary-60 h-6 w-10 rounded-full'
    >
      <RadixSwitch.Thumb className='block h-4 w-4 translate-x-[4px] rounded-full bg-white shadow-sm transition-all data-[state=checked]:translate-x-[18px]' />
    </RadixSwitch.Root>
  );
}
