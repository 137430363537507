import { InfoCircle } from '@kota/icons';
import { Popover } from '@kota/ui';

export type Props = {
  title: string;
  description?: string;
  tooltip?: string | JSX.Element;
};

export default function Helper({ title, description, tooltip }: Props): JSX.Element {
  return (
    <div className='space-y-2'>
      <div className='flex items-center space-x-2'>
        <div className='font-semibold'>{title}</div>
        {tooltip && <Popover trigger={<InfoCircle className='h-4 w-4' />}>{tooltip}</Popover>}
      </div>

      <div className='text-kota-text-30'>{description}</div>
    </div>
  );
}
