import type { PropsWithChildren } from 'react';
import { cn } from '@kota/utils';

type Props = PropsWithChildren<{
  className?: string;
}>;

export default function Card({ children, className }: Props): JSX.Element {
  return <div className={cn('rounded-2xl border p-6 shadow-sm', className)}>{children}</div>;
}
