import { Loading02 } from '@kota/icons';
import { cn } from '@kota/utils';

type Props = {
  className?: string;
};

export default function Spinner({ className = 'w-5 h-5' }: Props): JSX.Element {
  return <Loading02 className={cn(className, 'animate-spin')} />;
}
