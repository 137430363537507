import { Paragraph, Title } from '.';

type Props = {
  title?: string;
  data: {
    icon: React.ComponentType<React.ComponentProps<'svg'>>;
    title: string;
    description: string | JSX.Element;
  }[];
  footer?: string | JSX.Element;
};

export default function NextSteps({ title = 'Next steps:', data, footer }: Props): JSX.Element {
  return (
    <div className='space-y-2'>
      <Title size='20' weight='semibold'>
        {title}
      </Title>

      <div className='overflow-hidden rounded-2xl border p-4'>
        <div className='space-y-6'>
          {data.map((item, index) => (
            <div key={index} className='flex items-center space-x-4'>
              <div>
                <div className='text-kota-primary-60 bg-kota-neutral-5 flex h-10 w-10 items-center justify-center rounded-full'>
                  <item.icon className='h-6 w-6' />
                </div>
              </div>

              <div>
                <Title size='16' weight='medium'>
                  {item.title}
                </Title>

                <Paragraph size='14' className='text-kota-text-30'>
                  {item.description}
                </Paragraph>
              </div>
            </div>
          ))}
        </div>

        {footer && <div className='text-kota-text-30 -mx-4 -mb-4 mt-4 bg-muted px-5 py-2 text-sm'>{footer}</div>}
      </div>
    </div>
  );
}
