import type { PropsWithChildren } from 'react';
import { cn } from '@kota/utils';

type Props = PropsWithChildren & {
  size?: '14' | '16';
  className?: string;
};

export default function Paragraph({ size = '16', className, children }: Props): JSX.Element {
  const classes = cn(className, {
    'text-base': size === '16',
    'text-sm': size === '14',
  });

  return <p className={classes}>{children}</p>;
}
