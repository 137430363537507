import type { PropsWithChildren } from 'react';
import { cn } from '@kota/utils';

type Props = PropsWithChildren & {
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  type?: 'div' | 'button';
  onClick?: () => void;
  selected?: boolean;
};

export default function Tag({ icon: Icon, children, onClick, type = 'div', selected }: Props): JSX.Element {
  const Element = type;

  return (
    <Element
      className={cn('flex items-center space-x-1 rounded-md px-1.5 py-[3px] shadow', {
        'bg-kota-primary-80 text-white': selected,
      })}
      {...(type === 'button' ? { type: 'button', onClick } : {})}
    >
      {Icon && <Icon className={cn('text-kota-text-10 h-4 w-4', { 'text-kota-primary-20': selected })} />}
      <div>{children}</div>
    </Element>
  );
}
