import type { PropsWithChildren } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

type Props = PropsWithChildren & {
  options: JSX.Element[];
};

export default function Dropdown({ children, options }: Props): JSX.Element {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>{children}</DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className='w-72 rounded-xl border bg-white p-1 shadow-sm'>
          {options.map((option, index) => (
            <DropdownMenu.Item
              className='hover:bg-kota-neutral-5 cursor-pointer rounded-lg px-3 py-1.5 text-sm outline-none'
              key={index}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {option}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
