import { Paragraph, Title } from '.';

type Props = {
  title: string;
  description?: string | JSX.Element;
};

export default function Section({ title, description }: Props): JSX.Element {
  return (
    <div className='space-y-3'>
      <Title size='24' weight='semibold'>
        {title}
      </Title>

      {description && <Paragraph className='text-kota-text-40'>{description}</Paragraph>}
    </div>
  );
}
