import type { PropsWithChildren } from 'react';
import { useNavigate } from '@remix-run/react';

import { Sheet, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from './ui/sheet';

type Props = PropsWithChildren & {
  trigger?: string | JSX.Element;
  open?: boolean;
  title?: string;
  description?: string;
  backOnClose?: boolean;
  onClose?: () => void;
};

export default function KotaSheet({
  trigger,
  open,
  children,
  title,
  description,
  backOnClose,
  onClose,
}: Props): JSX.Element {
  const navigate = useNavigate();

  return (
    <Sheet
      open={open}
      onOpenChange={(state) => {
        if (!state) {
          if (backOnClose) navigate(-1);

          onClose?.();
        }
      }}
    >
      {trigger && <SheetTrigger>{trigger}</SheetTrigger>}

      <SheetContent className='!w-1/3 overflow-auto sm:max-w-full'>
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
          <SheetDescription className='text-kota-text-40'>{description}</SheetDescription>
        </SheetHeader>

        <div>{children}</div>
        <SheetFooter></SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
