import * as RadixTabs from '@radix-ui/react-tabs';

type Props = {
  defaultTab?: string;
  items: string[];
  content: JSX.Element[];
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
};

export default function Tabs({ className, defaultTab, items, content, value, onChange }: Props): JSX.Element {
  return (
    <RadixTabs.Root
      className={className}
      value={value}
      defaultValue={defaultTab}
      onValueChange={(value) => onChange?.(value)}
    >
      <RadixTabs.List className='rounded-lg bg-white p-1'>
        {items.map((item) => (
          <RadixTabs.Trigger
            key={item}
            className='data-[state=active]:bg-kota-primary-70 text-kota-text-30 cursor-pointer rounded-md px-3 py-1.5 font-medium data-[state=active]:text-white'
            value={item}
          >
            {item}
          </RadixTabs.Trigger>
        ))}
      </RadixTabs.List>

      <div className='p-4'>
        {content.map((item, index) => (
          // @ts-expect-error
          <RadixTabs.Content key={items[index]} value={items[index]}>
            {item}
          </RadixTabs.Content>
        ))}
      </div>
    </RadixTabs.Root>
  );
}
