import { AlertCircle, CheckCircle, InfoCircle } from '@kota/icons';
import { cn } from '@kota/utils';

export type Props = {
  variant: 'info' | 'warning' | 'error' | 'success' | 'neutral';
  title?: string;
  content?: string | JSX.Element;
};

export default function Message({ variant, title, content }: Props): JSX.Element {
  const classes = {
    border: cn('border rounded-xl border-dashed p-1', {
      'border-kota-primary-20': variant === 'info',
      'border-kota-warning-30': variant === 'warning',
      'border-kota-danger-30': variant === 'error',
      'border-kota-success-30': variant === 'success',
      'border-kota-neutral-20': variant === 'neutral',
    }),
    icon: cn('h-6 w-6 ring-4 rounded-full', {
      'text-kota-primary-80 fill-white ring-kota-primary-10': variant === 'info',
      'text-kota-warning-90 fill-white ring-kota-warning-30': variant === 'warning',
      'text-kota-danger-90 fill-white ring-kota-danger-30': variant === 'error',
      'text-kota-success-90 fill-white ring-kota-success-30': variant === 'success',
      'text-kota-neutral-90 fill-white ring-kota-neutral-10': variant === 'neutral',
    }),
    container: cn('rounded-lg p-4 flex items-start space-x-4', {
      'bg-kota-primary-5': variant === 'info',
      'bg-kota-warning-10': variant === 'warning',
      'bg-kota-danger-10': variant === 'error',
      'bg-kota-success-10': variant === 'success',
      'bg-kota-neutral-5': variant === 'neutral',
    }),
    title: cn({
      'text-kota-primary-80': variant === 'info',
      'text-kota-warning-90': variant === 'warning',
      'text-kota-danger-90': variant === 'error',
      'text-kota-success-90': variant === 'success',
      'text-kota-text-40': variant === 'neutral',
    }),
  };
  const Icon = {
    info: InfoCircle,
    warning: AlertCircle,
    error: AlertCircle,
    success: CheckCircle,
    neutral: InfoCircle,
  }[variant];

  return (
    <div className={classes.border}>
      <div className={classes.container}>
        <div>
          <Icon className={classes.icon} />
        </div>

        <div className='space-y-1'>
          {title && <div className={cn('font-medium', classes.title)}>{title}</div>}
          {content && <div className='text-kota-text-30 text-sm'>{content}</div>}
        </div>
      </div>
    </div>
  );
}
