import { useEffect, useState, type PropsWithChildren } from 'react';
import { ChevronDown } from '@kota/icons';
import { cn } from '@kota/utils';
import * as RadixCollapsible from '@radix-ui/react-collapsible';
import clsx from 'clsx';

type Props = PropsWithChildren & {
  trigger: JSX.Element;
  variant?: 'primary' | 'secondary' | 'naked';
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
};

export default function Collapsible({
  trigger,
  open: _open = false,
  variant = 'primary',
  children,
  onOpenChange,
}: Props): JSX.Element {
  const [open, setOpen] = useState(_open);

  useEffect(() => {
    onOpenChange?.(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setOpen(_open);
  }, [_open]);

  return (
    <RadixCollapsible.Root
      open={open}
      onOpenChange={setOpen}
      className={clsx('rounded-xl', {
        'border shadow-sm': variant === 'primary',
        'bg-kota-neutral-5': variant === 'secondary',
        '!rounded-none border-b': variant === 'naked',
      })}
    >
      <RadixCollapsible.Trigger className='flex w-full items-center justify-between p-4 text-left'>
        <div>{trigger}</div>

        <ChevronDown
          className={cn('text-kota-text-40 h-5 w-5 transition-all', {
            'rotate-180 transform': open,
          })}
        />
      </RadixCollapsible.Trigger>

      <RadixCollapsible.Content
        // We're using the `forceMount` and `h-0` approach to make sure form fields are rendered even when the collapsible is closed
        forceMount
        className={cn({
          'h-0 overflow-hidden': !open,
          'p-4': open,
        })}
      >
        {children}
      </RadixCollapsible.Content>
    </RadixCollapsible.Root>
  );
}
