import * as React from 'react';
import { type LegacyRef } from 'react';
import { FormError } from '@kota/ui';
import { cn } from '@kota/utils';
import { Label } from '@radix-ui/react-label';
import { useField } from 'remix-validated-form';

type ValidationBehavior = 'onSubmit' | 'onChange' | 'onBlur';

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  labelSize?: 'sm' | 'md' | 'lg';
  form?: string;
  blurred?: boolean;
  required?: boolean;
  validationBehavior?: {
    initial?: ValidationBehavior;
    whenTouched?: ValidationBehavior;
    whenSubmitted?: ValidationBehavior;
  };
}

function BaseTextarea(
  {
    name,
    label,
    labelSize = 'sm',
    form,
    blurred,
    required,
    validationBehavior = {
      initial: 'onSubmit',
      whenTouched: 'onSubmit',
      whenSubmitted: 'onChange',
    },
    ...rest
  }: TextareaProps,
  ref: LegacyRef<HTMLTextAreaElement>,
) {
  const { error, getInputProps } = useField(name, {
    formId: form,
    validationBehavior,
  });

  return (
    <div className='flex w-full flex-col space-y-2'>
      {label && (
        <Label
          aria-labelledby={name}
          htmlFor={name}
          className={cn('group-focus-within:text-kota-primary-50 text-kota-text-90 block select-none font-medium', {
            'text-sm': labelSize === 'sm',
            'text-base': labelSize === 'md',
            'text-lg': labelSize === 'lg',
          })}
        >
          {label}
          {required ? '*' : ''}
        </Label>
      )}

      <div
        className={cn(
          'group relative h-24 w-full rounded-xl border bg-white px-3 py-2 shadow-sm',
          'focus-within:ring-kota-primary-40 hover:border-kota-primary-40 focus-within:ring-2 focus-within:ring-offset-2 focus:outline-none',
          {
            'border-kota-neutral-20': !error,
            'border-red-500': error,
          },
          rest.className,
        )}
      >
        <textarea
          {...rest}
          {...getInputProps({ id: name })}
          onChange={(e) => {
            rest.onChange?.(e);
            getInputProps()?.onChange?.(e);
          }}
          name={name}
          ref={ref}
          className={cn(
            'text-kota-text-90 placeholder-kota-neutral-60 block h-full w-full resize-none border-0 p-0 pr-8',
            'focus:ring-0 focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0',
            {
              'blur focus:blur-none': blurred,
            },
          )}
        />
      </div>

      {error && <FormError>{error}</FormError>}
    </div>
  );
}

export default React.forwardRef(BaseTextarea);
